import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from "../components/layout"

const SpeakingPage = ({ data: { site: { siteMetadata: { title, siteUrl } } }, location: { pathname } }) =>
  <Layout>
    <Helmet
      title={`Speaking | ${title}`}
      meta={[
        { property: 'og:title', content: `Speaking | ${title}` },
        { property: 'og:url', content: `${siteUrl}${pathname}` },
      ]}
    />

    <h1 className="text-4xl font-bold leading-none mb-4">Speaking</h1>

    <p className="font-medium">
      If you'd like me to do a talk at your event, feel free to contact me via <a className="BodyLink" href="mailto:me@connerfritz.com?Subject=Speaking&body=Hi Conner,">email</a>. 
    </p>
    <p>
      I specialize in a variety of topics including web and game development, the intersection between programming and design, specific technology tutorials, imposter syndrome as well as hiring and engineering culture within organizations.
    </p>
  </Layout>

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`;

export default SpeakingPage
